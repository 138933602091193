.logoHeader {
  // width: 90pt;
  // height: 90pt;
  // /* position: fixed; */
  // position: relative;
  // top: -7px;
  // margin-top: 15px;
  width: 30pt;
  height: 90pt;
  /* position: fixed; */
  position: relative;
  top: -15px;
  margin-top: 15px;
}

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px !important;
}

.topBarActive {
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
    background-color: #142842;
    height: 100px;
    padding: 0px !important;
}

@media only screen and (max-width: 600px) {
  .logoHeader {
    width: 30pt;
    height: 71pt;
    top: 0px;
  }
}
