.centerBackground {
  background: url(./../../Assets/HeroSectionImage.svg);
  /* background-image: url('images/image.png'); */
  background-repeat: no-repeat;
  background-size: 50% 90%;
  height: 100%;
  width: 100%;
}

.h1Center {
  font-size: 45pt;
  text-align: left;
  color: #f6d869;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.h2Center {
  font-size: 35pt;
  text-align: left;
  color: #f6d869;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    font-size: 25pt;
  }
}

.h3Center {
  font-size: 25pt;
  text-align: left;
  color: #ffffff;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    font-size: 15pt;
    line-height: 1.8;
  }
}

.btns {
  margin-top: 50px;
  text-align: left;
}

.buttonCenter {
  color: #f6d869 !important;
  background-color: #0c1b30;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
  text-decoration: none !important;
  box-sizing: border-box;
  width: 120px;
  height: 35px;
  padding: 7px;
  border: 2px solid #64592f;
  border-radius: 13px;
  box-shadow: 0px 0px 10px -3px #f6d869 !important;
  margin: 0px 3px;
  border-radius: 30px;
  position: relative;

  @media only screen and (max-width: 769px) {
    margin: 5px 3px;
  }

  .text {
    margin-right: -20px;  
    background-color: #0000;
    transition: 0.8s;
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    // position: absolute;
    // top: 12px;
  }

  &:hover {

    .text {
      margin-right: 0px;  
      background-color: #0000;
      transition: 0.8s;
    }

    svg {
      display: inline;
      // transform: translateX(30px);
      opacity: 1;
      transition: 0.8s;
    }
  }
}

.buttonCenter1 {
  color: #f6d869;
  background-color: #0c1b30;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
  box-sizing: border-box;
  width: 120px;
  height: 35px;
  padding: 7px;
  border: 2px solid #64592f;
  border-radius: 13px;
  box-shadow: 0px 0px 10px -3px #f6d869;
  margin: 0px 3px;
  border-radius: 30px;
  position: relative;

  @media only screen and (max-width: 769px) {
    margin: 5px 3px;
  }
  .text {
    margin-right: -10px;
  }

  &.one {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.two {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(10px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.three {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(27px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.four {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    position: absolute;
    top: 10px;
  }

  &:hover {
    svg {
      display: inline;
      transform: translateX(15px);
      opacity: 1;
      transition: 0.8s;
    }
  }
}

.buttonCenter2 {
  color: #f6d869;
  background-color: #0c1b30;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
  box-sizing: border-box;
  width: 120px;
  height: 35px;
  padding: 7px;
  border: 2px solid #64592f;
  border-radius: 13px;
  box-shadow: 0px 0px 10px -3px #f6d869;
  margin: 0px 3px;
  border-radius: 30px;
  position: relative;

  @media only screen and (max-width: 769px) {
    margin: 5px 3px;
  }
  .text {
    margin-right: -10px;
  }

  &.one {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.two {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(10px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.three {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(27px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.four {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    position: absolute;
    top: 10px;
  }

  &:hover {
    svg {
      display: inline;
      transform: translateX(10px) !important;
      opacity: 1;
      transition: 0.8s;
    }
  }
}

.buttonCenter3 {
  color: #f6d869;
  background-color: #0c1b30;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
  box-sizing: border-box;
  width: 120px;
  height: 35px;
  padding: 7px;
  border: 2px solid #64592f;
  border-radius: 13px;
  box-shadow: 0px 0px 10px -3px #f6d869;
  margin: 0px 3px;
  border-radius: 30px;
  position: relative;

  @media only screen and (max-width: 769px) {
    margin: 5px 3px;
  }
  .text {
    margin-right: -10px;
  }

  &.one {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.two {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(10px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.three {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(27px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.four {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    position: absolute;
    top: 10px;
  }

  &:hover {
    svg {
      display: inline;
      transform: translateX(27px) !important;
      opacity: 1;
      transition: 0.8s;
    }
  }
}

.buttonCenter4 {
  color: #f6d869;
  background-color: #0c1b30;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
  box-sizing: border-box;
  width: 120px;
  height: 35px;
  padding: 7px;
  border: 2px solid #64592f;
  border-radius: 13px;
  box-shadow: 0px 0px 10px -3px #f6d869;
  margin: 0px 3px;
  border-radius: 30px;
  position: relative;

  @media only screen and (max-width: 769px) {
    margin: 5px 3px;
  }
  .text {
    margin-right: -10px;
  }

  &.one {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.two {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(10px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.three {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(27px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  &.four {
    svg {
      display: inline;
      transform: translateX(0px);
      opacity: 0;
      transition: 0.8s;
      position: absolute;
      top: 10px;
    }

    &:hover {
      svg {
        display: inline;
        transform: translateX(15px) !important;
        opacity: 1;
        transition: 0.8s;
      }
    }
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    position: absolute;
    top: 10px;
  }

  &:hover {
    svg {
      display: inline;
      transform: translateX(15px) !important;
      opacity: 1;
      transition: 0.8s;
    }
  }
}

@media only screen and (max-width: 600px) {
  .displaynonemobile {
    display: none;
  }
  .centerBackground {
    display: none;
  }
  .bg-img {
    display: none;
  }
  .removeonWeb {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) {
  .removeonWeb {
    display: none !important;
  }
  .displaynonemobile {
    display: block;
  }
}

.linkTextBtn {
  border: 1px solid #f6d869 !important;
}

.header {
  position: relative !important;
  padding: 10rem 0px !important;
  width: 100% !important;
  overflow-x: hidden !important;

  &_img {
    position: absolute !important;
    top: 0 !important;
    right: -10% !important;
    width: 55% !important;
  }
}
