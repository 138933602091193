* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 2px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #10223c;
  outline: 0px solid slategrey;
}

.pos {
  &-relative {
    position: relative !important;
  }
  &-absolute {
    position: absolute !important;
  }
  &-fixed {
    position: fixed !important;
  }
}

.flex {
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

// navbar

.linkText {
  text-decoration: none;
  color: #f6d869 !important;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 0%;
    height: 1px;
    position: absolute;
    top: 45px;
    left: 13px;
    background: #cfb961;
    transition: 0.8s;
  }

  &:hover {
    &::after {
      width: 85%;
      transition: 0.8s;
    }
  }
}

.linkTextBtn {
  border: 1px solid #f6d869 !important;
  border-radius: 30px !important;
  box-shadow: 0px 0px 10px -3px #f6d869 !important;

  position: relative;

  @media only screen and (max-width: 769px) {
    margin: 5px 3px;
  }
  .text {
    margin-right: -10px;
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    position: absolute;
    top: 12px;
  }

  &:hover {
    svg {
      display: inline;
      transform: translateX(15px);
      opacity: 1;
      transition: 0.8s;
    }
  }
}

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px !important;
  overflow: hidden;
}

.topBarActive {
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
  background-color: #142842;
  height: 100px;
  padding: 0px !important;
}

.btnCenter {
  color: #f6d869;
  background-color: #0c1b30;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins";
  box-sizing: border-box;
  width: 200px;
  height: 45px;
  padding: 7px;
  border: 2px solid #64592f;
  border-radius: 30px;
  box-shadow: 0px 0px 10px -3px #f6d869 !important;
  /* margin: 50px 13px; */
  position: relative;
  padding-bottom: 39px;

  .text {
    margin-right: -20px;
    background-color: #0000;
    transition: 0.8s;
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    // position: absolute;
    // top: 12px;
  }

  &:hover {
    .text {
      margin-right: 0px;
      background-color: #0000;
      transition: 0.8s;
    }

    svg {
      display: inline;
      // transform: translateX(30px);
      opacity: 1;
      transition: 0.8s;
    }
  }
}

// header section
.header {
  position: relative;
  padding: 20rem 0px 0rem;

  @media only screen and (max-width: 768px) {
    padding: 0rem 0px 0px;
  }

  &_img {
    // position: absolute !important;
    // top: 100px !important;
    // right: -15% !important;
    // width: 65% !important;
    position: absolute !important;
    top: 110px !important;
    right: -15% !important;
    width: 64% !important;
    // position: absolute !important;
    // top: 45px !important;
    // right: -25% !important;
    // width: 85% !important;
  }

  .social-header {
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      transition: 0.5s;
      transform: rotate(360deg);
    }
  }

  .lorem {
    color: #aaa;
    text-align: left;
    padding-right: 60px;
  }

  &-divider {
    width: 100px;
    height: 2px;
    background-color: #f6d869;
    margin: 15% 0px 0px;

    @media only screen and (max-width: 768px) {
      margin: 30% 0px 0px;
    }
  }
}

.desktop-only {
  z-index: 999;
}
@media only screen and (max-width: 1280px) {
  .px1280above {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }
}
@media only screen and (min-width: 1279px) {
  .px1280 {
    display: none !important;
  }
}
// farm

.farm {
  position: relative;

  .img {
    &_bg {
      position: absolute;
      z-index: 99;

      @media only screen and (max-width: 768px) {
        width: 100%;
        height: 120vh;
      }
    }
  }
}

.farm-cards {
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;
  background-color: #23436e !important;
  background: transparent linear-gradient(326deg, #152e51 0%, #23436e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px !important;
  padding: 30px;
  position: relative;
  overflow: visible;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
    background-color: #0000;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    img {
      transition: 0.5s;
      transform: rotate(360deg);
    }
  }
  img {
    transition: 0.5s;
    transform: rotate(0deg);
  }

  p {
    text-align: left;
    font-size: 16px;
    opacity: 0.7;
  }

  &-overlay {
    width: 345px;
    height: 100%;
    transform: rotate(94deg);
    background-color: #000;
    position: absolute;
    top: 18px;
    left: 70px;
    border-radius: 10px;
    opacity: 0.6;
    @media only screen and (max-width: 960px) {
      left: calc(50vw - 175px);
    }
    @media only screen and (max-width: 768px) {
      width: 320px;
      height: 95%;
      transform: rotate(3deg);
      left: calc(50vw - 150px);
    }
  }
}

// chart

.chart-section {
  min-height: 70vh;

  .bg_img {
    position: absolute;
    top: -270px;
    left: -360px;

    @media only screen and (max-width: 768px) {
      top: -90px;
      left: -360px;
      width: 204%;
    }

    @media only screen and (max-width: 365px) {
      top: -10px;
    }

  }

  .chart_left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      width: 250px;
      margin: 0px 50px;
      padding-bottom: 30px;
      font-size: 55px;
      font-weight: 400;
    }

    p {
      width: 250px;
      margin: 0 50px;
    }
  }
}

.card_text {
  text-align: left;
  font-size: 16px;
  opacity: 0.7;
}

// chart

.chart {
  margin-top: 10pc;
  margin-left: 1pc;

  .pieChart_img {
    width: 95%;
    margin-top: 10%;
  }
}

.pieChart_img {
  width: 95%;
  margin-top: 5%;
}

// road map

.road_map_dot {
  width: 15%;
  border: 2px dashed #f6d869;
  height: 2px;
  position: absolute;

  @media only screen and (max-width: 1279px) {
    width: 30%;
  }

  &.one {
    left: 14%;
    bottom: 38%;
    transform: rotate(-29deg);
    @media only screen and (max-width: 1280px) {
      left: 19%;
      bottom: 60%;
    }
    @media only screen and (max-width: 1279px) {
      left: 34.5%;
      bottom: 76%;
      transform: rotate(90deg);
      max-height: 120px;
    }
  }

  &.two {
    left: 33.2%;
    bottom: 38%;
    transform: rotate(29deg);
    @media only screen and (max-width: 1280px) {
      left: 42.2%;
      bottom: 60%;
    }
    @media only screen and (max-width: 1279px) {
      left: 34.5%;
      bottom: 58%;
      transform: rotate(90deg);
    }
  }

  &.three {
    left: 51.5%;
    bottom: 38%;
    transform: rotate(-29deg);
    @media only screen and (max-width: 1280px) {
      left: 65.5%;
      bottom: 60%;
    }
    @media only screen and (max-width: 1279px) {
      left: 34.5%;
      bottom: 39%;
      transform: rotate(90deg);
    }
  }

  &.four {
    left: 70%;
    bottom: 38%;
    transform: rotate(29deg);
    @media only screen and (max-width: 1280px) {
      left: 61%;
      bottom: 35%;
      transform: rotate(142deg);
    }
    @media only screen and (max-width: 1279px) {
      left: 34.5%;
      bottom: 21%;
      transform: rotate(90deg);
    }
  }

  &.five {
    left: 57%;
    bottom: 42%;
    transform: rotate(-29deg);
  }
}

// ready section
.ready {
  background: #10213b;
  // background-image: linear-gradient(to right, #0000, #0005);
  min-height: 60vh;
  overflow: hidden !important;

  .bg {
    &_img {
      &_1 {
        position: absolute;
        right: 35%;
        top: 0%;
        z-index: 10;

        @media only screen and (max-width: 768px) {
          position: absolute;
          right: 25%;
          top: 25%;
          z-index: 10;
          width: 100%;
        }
      }

      &_2 {
        position: absolute;
        top: 0;
        right: -20%;
        height: 101%;
        z-index: 10;

        @media only screen and (max-width: 768px) {
          position: absolute;
          top: 0;
          right: -51%;
          height: 70%;
          z-index: 10;
          width: 100%;
        }
      }
    }
  }

  .readyh1 {
    font-size: 90px !important;
    font-family: "Poppins";
    font-weight: 700;
    color: #f6d869;
    text-align: center;
    line-height: 1.3 !important;
  }

  .readytext {
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 100;
    color: #ffffff;
    text-align: center;
    margin: 15px 0px;
  }

  .readybutton {
    height: 35px;
    width: 130px;
    border-style: solid;
    border-width: 2px;
    border-color: #64592f;
    border-radius: 15px;
    box-shadow: blurr 0px 0px 10px -3px #f6d869 !important;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 100;
    color: #f6d869;
    text-align: center;
    background-color: transparent;
  }
}

// footer
.footer {
  img {
    margin: 0px 6px;
    cursor: pointer;

    &:hover {
      transition: 0.5s;
      transform: rotate(360deg);
    }
  }

  &-divider {
    width: 100px;
    height: 2px;
    background-color: #fff2;
    margin: 15px 0;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
// .ready {

// }

.App-link {
  color: #61dafb;
}
.bg-img {
  width: 90%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  .bg-img {
    display: none;
  }
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiSvgIcon-fontSizeLarge {
  color: #f6d869 !important;
  position: fixed;
  right: 10;
  top: 42px;
}

.MuiTypography-colorTextSecondary {
  color: #ffffff !important ;
}
@media only screen and (min-width: 600px) {
  .removeonWeb {
    display: none !important;
  }
  /* .displaynonemobile {
    display: none;
  } */
}
@media only screen and (max-width: 600px) {
  .removeonWeb {
    display: block !important;
  }
  /* .displaynonemobile {
    display: block;
  } */
}
