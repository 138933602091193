.readyh1 {
  font-size: 50px;
  font-family: "Poppins";
  font-weight: 700;
  color: #f6d869;
  text-align: center;
  z-index: 999;
}

.readytext {
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 100;
  color: #ffffff;
  text-align: center;
  margin: 15px 0px;
  line-height: 1.5;
}
.normaltext {
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 100;
  color: #ffffff;
  text-align: center;
  margin: 15px 0px;
  line-height: 1.5;
}
.readybutton {
  height: 45px;
  width: 150px;
  border-style: solid;
  border-width: 2px;
  border-color: #64592f;
  border-radius: 30px;
  box-shadow: 0px 0px 10px -3px #f6d869 !important;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 100;
  color: #f6d869 !important;
  text-decoration: none !important;
  text-align: center;
  background-color: transparent;
  position: relative;
  padding-top: 7px;

  
  .text {
    margin-right: -20px;  
    background-color: #0000;
    transition: 0.8s;
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    // position: absolute;
    // top: 12px;
  }

  &:hover {

    .text {
      margin-right: 0px;  
      background-color: #0000;
      transition: 0.8s;
    }

    svg {
      display: inline;
      // transform: translateX(30px);
      opacity: 1;
      transition: 0.8s;
    }
  }
}
