.tradingh1 {
  font-size: 45px;
  color: #f6d869;
  font-family: "Poppins";
  text-align: left;
  margin-top: 200px;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #f6d869;
    position: absolute;
    left: 50px;
    bottom: -10px;
    transform: translateX(-50%);
    @media only screen and (max-width: 600px) {
      left: 50%;
      bottom: -10px;
      transform: translateX(-50%);
    }


  }

  @media only screen and (max-width: 1370px) {
    margin-top: 200px;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 60px;
  }

  @media only screen and (max-width: 600px) {
    margin-top: -15px;
    text-align: center;
  }
}



.tradingtext {
  margin-top: 30px;
  font-size: 16px;
  font-weight: lighter;
  color: #ffffff;
  font-family: "Poppins";
  text-align: left;

  @media only screen and (max-width: 1370px) {
    margin-top: 13px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 16px;
  }
}

.tradingtext span {
  color: #f0b90b;
}

.tradingcenterBackground {
  /* background: url(./../../Assets/Tradingbotimage.png); */
  // background-image: url("./../../Assets/Tradingbotimage.png");
  background-repeat: no-repeat;
  background-size: 80% 90%;
  height: 100%;
  width: 100%;
  float: right;
  background-position-x: 170%;
  position: relative;
  background-color: rgb(16, 34, 60);
  margin-top: -100px;
  min-height: 110vh;
  padding-top: 20vh;

  @media only screen and (max-width: 1370px) {
    min-height: 120vh;
  }

  @media only screen and (max-width: 768px) {
    height: 130vh;
  }
}

.tokenomics {
  font-size: 35px;
  font-family: "Poppins";
  font-weight: 100;
  color: #ffffff;
  text-align: left;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}

.totalsupply {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 100;
  color: #ffffff;
  text-align: left;
  margin-top: 30px;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}

.numbers {
  font-size: 70px;
  font-family: "Poppins";
  font-weight: 100;
  color: #f6d869;
  text-align: left;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #f6d869;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}

.bg {
  &_img {
    &_1 {
      position: absolute;
    }
    &_2 {
      position: absolute;
      z-index: 9;
      left: -40%;
      top: 0%;
      width: 95%;

      @media only screen and (max-width: 768px) {
        position: absolute;
        z-index: 9;
        left: 25%;
        top: 0%;
        width: 160%;
      }
    }
    &_3 {
      position: absolute;
      width: 75%;
      right: -12%;
      top: 20px;

      @media only screen and (max-width: 768px) {
        position: relative;
        width: 142%;
        left: 0px;
      }
    }
  }
}
