.textStyle {
  font-size: 16pt;
  font-weight: lighter;
  font-family: "Poppins";
  text-align: justify;
  color: #ffffff;
  margin: 50px 5px;
  text-align: center;
  opacity: 0.8;
  letter-spacing: 0.02em;
  z-index: 9999;
  text-align: center;
  font: normal normal 200 20px/34px 'Poppins';
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;

  @media only screen and (max-width: 768px) {
    font-size: 10pt;
  }
}

.buttonCenter {
  color: #f6d869;
  background-color: #0c1b30;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins";
  box-sizing: border-box;
  width: 200px;
  height: 45px;
  padding: 7px;
  border: 2px solid #64592f;
  border-radius: 30px;
  box-shadow: 0px 0px 10px -3px #f6d869 !important;
  margin: 50px 13px;
  position: relative;

  .text {
    margin-right: -10px;  
  }

  svg {
    display: inline;
    transform: translateX(0px);
    opacity: 0;
    transition: 0.8s;
    position: absolute;
    top: 12px;
  }

  &:hover {
    svg {
      display: inline;
      transform: translateX(30px);
      opacity: 1;
      transition: 0.8s;
    }
  }
}


.heading {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 55px;
  text-align: center;
  font-weight: 500;
  color: #f6d869;
  padding-bottom: 25px;
  font-weight: bold;
  position: relative;
  margin-top: 100px;
  margin-bottom: 50px;

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 1px;
    background-color: #f6d86900;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}